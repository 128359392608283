<template>
  <div class="wordbook animate__animated animate__fadeIn">
    <div class="header">
      <Headers :header_data="[{ title: 'AI单词本' }]"/>
    </div>
    <main>
      <div class="tabs">
        <el-tabs tab-position="left" v-model="tabs" @tab-click="tabClick" :stretch="false" style="height: 200px;">
          <el-tab-pane name="question">
            <div slot="label" class="tablabel">
              <i>
                <img src="../../../assets/images/student/bluecuo.png" v-if="tabs == 'question'" alt="">
                <img src="../../../assets/images/student/ccccuo.png" v-else alt="">
              </i>
              <span>错词本</span>
            </div>
          </el-tab-pane>
          <el-tab-pane label="我的收藏" name="collection">
            <span slot="label" class="tablabel">
              <i>
                <img src="../../../assets/images/student/bluexing.png" v-if="tabs == 'collection'" alt="">
                <img src="../../../assets/images/student/cccxing.png" v-else alt="">
              </i>
              <span>我的收藏</span>
            </span>
          </el-tab-pane>
          <el-tab-pane label="AI智能" name="AIhome">
            <span slot="label" class="tablabel">
              <i>
                <i class="el-icon-user-solid" v-if="tabs == 'AIhome'" style="position: relative;top: 11px"></i>
                <i class="el-icon-user" v-else style="position: relative;top: 11px"></i>
              </i>
              <span>AI智能</span>
            </span>
          </el-tab-pane>
        </el-tabs>
      </div>
      <router-view></router-view>
    </main>
    <Footer />
  </div>
</template>
<script>
import { getGroupStudentCount, getErrorWordHome, getWrongWords } from '@/api/student'
export default {
  data(){
    return{
      tabs:'1', //tab切换
      options: [],
      source: null, // 错词来源
      wrong_data: {
        bjpj: 0,
        cczs: 0,
        hyy: 0,
        hyyList: [],
        jrcc: 0,
        jrccList: [],
        kypy: 0,
        kypyList: [],
        tyxy: 0,
        tyxyList: [],
        xzpj: 0,
        yyh: 0,
        yyhList: []
      },
      currentData: {}
    }
  },
  components:{
    Headers:(resolve) => {
      require(['@/components/student/header.vue'], resolve)
    },
    Footer:(resolve) => {
      require(['@/components/student/footer.vue'], resolve)
    },
  },
  mounted(){

    this.tabs = this.$route.name
  },
  methods:{
    //  --------------------------------------------------tab切换
    tabClick(){
      if(this.tabs == 'question'){
        this.$router.replace({path: '/wordbook/question'})
      } else if (this.tabs == 'collection'){
        this.$router.replace({path:'collection'})
      } else if (this.tabs == 'AIhome') {
        this.$router.replace({path: '/wordbook/AIhome'})
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.header{
  width: 100%;
  height: 90px;
  box-shadow: 0 2px 4px 2px rgba(89,144,255,.1);
}
main{
  position: absolute;
  top: 90px;
  left: 0;
  bottom: 50px;
  right: 0;
  box-sizing: border-box;
  padding: 2% 0;
  .tabs{
    height: 100%;
    width: 10vw;
    border-radius: 10px;
    box-shadow: 0 0 8px 4px rgba(89,144,255,.2);
    float: left;
    box-sizing: border-box;
    padding-top: 2%;
  }
}
.tablabel{
  position: relative;
  display: block;
  width:fit-content;
  width:-webkit-fit-content;
  width:-moz-fit-content;
  height: 100%;
  // margin: auto;
  i{
    width: 1.1vw;
    height: 1.1vw;
    display: block;
    float: left;
    margin-top: 3px;
    margin-right: .4vw;
    img{
      display: inline-block;
      width: 1.1vw;
      height: 1.1vw;
    }
  }
  span{
    display: block;
    float: left;
  }
}
.selsectbox{
  width:130px;
  background: #fff;
  border-radius: 4px;
}
  //自定义el-input框的一些自定义样式
/deep/ .selsectbox .el-input--small .el-input__inner{
    // background-color: #e6f0ff;
    padding-left:40px;
    height:50px;
    border-radius: 5px;
    // color: #1890FF;
    font-size: 18px;
    border: 1px solid #1890FF;
    font-weight: 600;
}
//自定义左边图标
// .selsectbox /deep/.el-input__inner{
//   background: url('../../../assets/images/student/left.png') no-repeat center center; //引入icon
//   background-size: 18px 18px;  //这个是图片的大小，在这里不能直接设置width  height,设置宽高其实是select的宽高，图片可能会失真只设置宽度  高度auto也行
//   background-position:0.5vw 50%; //在input中定位置  可以调整自定义icon的左右上下位置
//   padding: 0 0 0 2.5vw; //需要设置padding 把placeholder向右移
//   box-sizing: border-box;
//   font-size: 14px;
// }
//替换右边边默认图标
/deep/.selsectbox .el-icon-arrow-up::before {
      content: "";
      background: url('../../../assets/images/student/triangle.png') center center no-repeat;
      position: absolute;
      background-size: 18px 12px;
      width: 100%;
      height: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(180deg);
}
/deep/ .selsectbox .el-input--small input::-webkit-input-placeholder {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 550;
      // color: #2881FF;
  }
</style>
<style lang="scss">
.wordbook{
  .el-tabs__header.is-left {
    width: 10vw;
    .el-tabs__active-bar{
      display: none;
    }
    #tab-first {
      font-size: 20px;
      padding: 0;
      // text-align: center;
    }
    .is-active {
      background-color: #d8effb;
    }
    .el-tabs__header.is-left .el-tabs__nav > div {
      width: 3px !important;
    }
  }
  .el-tabs__nav{
    padding-top: 10px;
  }
  .el-tabs__item{
    color: #999;
    height: 3vw;
    line-height: 3vw;
    font-size: 1.15vw;
  }
  .el-tabs__item.is-active {
    color: #409EFF !important;
    border-right: 5px solid #409EFF;
  }
  .el-tabs__item:hover {
    color: #409EFF !important;
  }
  .el-tabs__header.is-left .is-active{
    background: #FFFFFF !important;
    box-shadow: 0px 0px 10px 0px rgba(89, 144, 255, 0.3) !important;
  }
}
</style>